import gql from 'graphql-tag';

export const UPDATE_SETTINGS = gql`
  mutation update_settings($id: ID!, $code: Int!, $build_number: String!, $native_android_version: String!, $native_ios_version: String! ) {
    update_settings(
      id: $id
      latest_active_code: $code
      build_number: $build_number
      native_android_version: $native_android_version
      native_ios_version: $native_ios_version
    ) {
      status
      message
    }
  }
`;

export default UPDATE_SETTINGS;
